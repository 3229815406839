// @ts-nocheck

import { Call, StyledDataGrid, useMenuDrawerStoreSelectors } from "@/shared";
import { Box, Menu, MenuItem } from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid";
import { useState } from "react";
import { FcDeleteDatabase } from "react-icons/fc";
import { tableColumns } from "../helpers";
import { TechFieldsCheckbox } from "./TechFieldsCheckbox";
import { Controller, useForm } from "react-hook-form";
import { FilteredField } from "../CallsJournalPage";

interface CallsTableProps {
  calls: Call[];
  boxHeight: number;
  isLoading: boolean;
  filteredFields: FilteredField[];
  setFilteredFields: (value: FilteredField[]) => void;
}

export const CallsTable = ({
  calls,
  boxHeight,
  isLoading,
  filteredFields,
  setFilteredFields,
}: CallsTableProps) => {
  const isMenuDrawerOpen = useMenuDrawerStoreSelectors.use.isMenuDrawerOpen();

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "calldate", sort: "asc" },
  ]);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
    columnField: string;
    columnValue: string | boolean;
  } | null>(null);

  const { control, watch } = useForm({
    values: {
      isTechFields: false,
    },
  });

  const isTechFields = watch("isTechFields");

  const height = `${boxHeight + 35}px`;
  const width = window.innerWidth - (isMenuDrawerOpen ? 240 + 24 : 64 + 26);

  const onItemSelect = () => {
    if (contextMenu) {
      // @ts-ignore
      setFilteredFields((prev: FilteredField[]) => {
        const existingIndex = prev.findIndex(
          (item) => item.columnField === contextMenu.columnField
        );

        if (existingIndex !== -1) {
          const updatedFields = [...prev];
          updatedFields[existingIndex].columnValue = contextMenu.columnValue;
          return updatedFields;
        } else {
          return [
            ...prev,
            {
              columnField: contextMenu.columnField,
              columnValue: contextMenu.columnValue,
            },
          ];
        }
      });
    }

    setContextMenu(null);
  };

  const handleContextMenu = (
    event: React.MouseEvent,
    columnField: string,
    columnValue: string | boolean
  ) => {
    event.preventDefault();

    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
            columnField,
            columnValue,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const filterCalls = (
    calls: Call[],
    filteredFields: { columnField: string; columnValue: string | boolean }[]
  ) => {
    return calls.filter((call) => {
      return filteredFields.every((filter) => {
        return call[filter.columnField as keyof Call] === filter.columnValue;
      });
    });
  };

  const filteredCalls = filterCalls(calls, filteredFields);

  return (
    <>
      <Controller
        control={control}
        name="isTechFields"
        render={({ field: { value, onChange } }) => (
          <TechFieldsCheckbox checked={value} onChange={onChange} />
        )}
      />

      <Box
        sx={{
          height: `calc(100vh - ${height})`,
          width: width,
          maxWidth: width,
        }}
      >
        <StyledDataGrid
          rows={filteredCalls}
          columns={tableColumns(calls, isTechFields, handleContextMenu)}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          hideSortIcons={false}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          pageSizeOptions={[100]}
          getRowId={(call: Call) => call.uniqueid}
          rowHeight={35}
          columnHeaderHeight={35}
          disableMultipleRowSelection
          hideFooterSelectedRowCount
          checkboxSelection
          disableColumnMenu
          loading={isLoading}
          slots={{
            noRowsOverlay: () => (
              <Box
                display="flex"
                height="100%"
                alignItems="center"
                justifyContent="center"
                fontWeight="500"
                fontSize={14}
                flexDirection="column"
              >
                <FcDeleteDatabase
                  fontSize={120}
                  style={{ marginBottom: "10px" }}
                />

                <Box>На сьогодні дзвінків не зафіксовано.</Box>

                <Box>
                  Будь ласка, виберіть іншу дату для перегляду дзвінків.
                </Box>
              </Box>
            ),
          }}
        />
      </Box>

      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={onItemSelect} style={{ fontSize: 14 }}>
          Знайти:{" "}
          {typeof contextMenu?.columnValue === "boolean"
            ? "№1 - Yes"
            : contextMenu?.columnValue}
        </MenuItem>
      </Menu>
    </>
  );
};
